/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

const jQuery = require('jquery');
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('jquery-migrate');

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo;

import SimpleBar from 'simplebar';
global.SimpleBar = SimpleBar;
window.SimpleBar = SimpleBar;

const tIppy = require('tippy.js').default;
global.tippy = tIppy;
window.tippy = tIppy;

const cLipboard = require('clipboard');
global.ClipboardJS = cLipboard;
window.ClipboardJS = cLipboard;

const snackBar = require('../src/theme/snackbar');
global.Snackbar = snackBar;
window.Snackbar = snackBar;


require('../src/jets/crud');
require('../src/theme/report-validity');
require('../src/theme/mmenu.min');
require('../src/theme/bootstrap-slider.min');
require('../src/theme/bootstrap-select.min');
require('../src/theme/waypoint');
require('../src/theme/counterup.min');
require('../src/theme/magnific-popup.min');
require('../src/theme/slick.min');
require('../src/theme/custom');
require('../src/theme/leaflet.min.js');
require('../src/theme/leaflet-markercluster.min.js');
require('../src/theme/leaflet-gesture-handling.min.js');
require('../src/theme/leaflet-hireo.js');